import "../styles/ItemsBlock.css"
import ItemCard from "../components/ItemCard";
import db from "../db.json"
import { NavLink } from "react-router-dom";
import BannerItem from "../components/BannerItem";
import BannerItem2 from "../components/BannerItem2";

const ItemsPage = () => {
    return (
        <div className="itemsPage">
        <div className="itemsBlock">
            <BannerItem />
            <BannerItem2 />
            {db.items.map((item, index) => { 
                // if (item.id === "sto1" || item.id === "sto2"){
                return <nav><NavLink to={`/items/${item.id}`} className="NavLink"> <ItemCard key={item.id} item={item}/></NavLink></nav>
                // }
            })}
        </div>
        </div>
    );
}

export default ItemsPage;
import "../styles/Gallery.css"
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
// import { isVisible } from "@testing-library/user-event/dist/utils";
// import { Hidden } from "@mui/material";

const Gallery = (db) => {
    const { id } = useParams();

    const [galleryArr, setGalleryArr] = useState([]);

    console.log(db);

    useEffect(() => {
        setGalleryArr([]);

        const item = db.data.items.find(el => el.id === id) ?? null;
        const imageArr = item ? (item.gallery ?? '').split(';') : [];

        imageArr.forEach((item) => {
            let img = new Image();
            img.src = item;
            img.onload = function () {
                let isVertical = (this.width < this.height) ? true : false;
                setGalleryArr(prevGalleryArr => {
                    let updatedArr = [...prevGalleryArr, { src: item, vertical: isVertical }];
                    updatedArr.sort((a, b) => {
                        const aName = a.src.split('/').pop();
                        const bName = b.src.split('/').pop();
                        return aName.localeCompare(bName);
                    });
                    return updatedArr;
                });
            };
        });
    }, [db.data.items, id]);


        const [zoomImage, setImage] = useState({ image: '' });

        const viewImage = (image) => {
            setImage({ image })
            document.body.style.overflow = 'hidden';
        }

        const actionEvent = (action) => {
            if(action === "close"){
                setImage({image: ''})
                document.body.style.overflow = '';
            }
    }


    return (
        <div>
            {zoomImage.image &&
                <div className="bgDiv">
                    <div className="bgImage" onClick={() => actionEvent("close")}></div>
                    <img className="zoomImage" src={zoomImage.image} alt="ZoomImage"></img>
                </div>
            }
            <div style={{ visibility: (galleryArr.length > 0) ? 'visible' : 'hidden' }}>
                <span className="galleryH1">Галерея</span>
                <div className="galleryBox">
                    {galleryArr.map((image, index) => {
                        return <div key={"img" + index} className={"galleryImage" + (image.vertical ? " Vertical" : " Horizontal")} style={{ backgroundImage: `url(${image.src})` }} onClick={() => viewImage(image.src)}></div>
                    })}
                </div>
            </div>
        </div>
    );
}

export default Gallery;
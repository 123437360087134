import "../styles/Page404.css"

const Page404 = () => {
    return (
        <div className="page404">
            <h1 className="text404">404</h1>
            <h2 className="notFound">Страница не найдена</h2>
        </div>
    );
}

export default Page404;
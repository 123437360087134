import "../styles/ServicesBlock.css"
import ServicesCardMini from "./ServicesCardMini";
import dbservices from "../dbservices.json"
import ServicesCardMax from "./ServicesCardMax";

const ServicesBlock = () => {
    return (
        <div className="servicesBlockAlign" id="services">
            <span className="servicesText">Мы производим следующие виды работ</span>
            <div className="servicesCardBoxAlign">
                <div className="servicesCardBox">
                    {dbservices.cards.slice(0, 6).map((card) => {
                        return <ServicesCardMini key={card.id} card={card} />
                    })
                    }
                    {dbservices.cards.slice(6, 8).map((card) => {
                        return <ServicesCardMax key={card.id} card={card} />
                    })
                    }
                </div>
            </div>
        </div >
    );
}

export default ServicesBlock;
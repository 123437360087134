import "../styles/Banner.css"
import imagebanner from "../image/banner1.jpg"
import { NavLink } from "react-router-dom";
import db from "../db.json"
import ItemCard from "../components/ItemCard";

const BannerItem = () => {
    return (
        <div className="banner">
            <div className="all-banner-align">
                <div className="slogan-align">
                    <h1 className="slogan">Уникальные товары собственного производства —</h1>
                </div>
                <div className="banner-align bannerItem">
                    <div className="banner-content itemContent">
                        <div className="image-banner"><img className="image-banner" src={imagebanner} style={{objectPosition: 'center -20px',}} alt="banner"></img></div>
                        <div className="itemBanner">
                            {db.items.map((item, index) => {
                                if (item.id === "sto2") {
                                    return <nav><NavLink to={`/items/${item.id}`} className="NavLink"> <ItemCard key={item.id} item={item} /></NavLink></nav>
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerItem;
import "../styles/Footer.css"
import logo from "../image/logoWhite.svg"

const Footer = () => {
    return (
        <div className="footer">
            <div className="alignFooter">
                <img className="logoFooter" src={logo} alt="logo"></img>
                <div className="date">© 2012 — 2023</div>
                <div className="aboutFooter">
                    <div className="adress">Ореховый бульвар, д. 71</div>
                    <div className="vixi">Без выходных 24 / 7</div>
                    <div className="number">+7 (966) 077-22-66</div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
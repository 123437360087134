import Banner from "../components/Banner";
import Footer from "../components/Footer";
import ItemsBlock from "../components/ItemsBlock";
import ServicesBlock from "../components/ServicesBlock";

const HomePage = () => {
    return (
        <div>
            <Banner />
            <ItemsBlock />
            <ServicesBlock />
            <Footer />
        </div>
    );
}

export default HomePage;
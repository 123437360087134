import "../styles/ItemsBlock.css"
import ItemCard from "./ItemCard";
import db from "../db.json"
import arrowText from "../image/arrowText.svg"
import { NavLink } from "react-router-dom";

const ItemsBlock = () => {

    return (
        <div className="itemBlock" id="itemblocklink">
            <div className="alignBlock">
                <nav className="itemTextBoxAlign">
                    <NavLink to="/items" className="hoverTextBox">
                        <div className="itemTextAlign">
                            <span className="itemText">Все Товары</span>
                            <img src={arrowText} className="itemArrowText" alt="→" />
                        </div>
                        <div className="itemTextLine"></div>
                    </NavLink>
                </nav>
                <div className="itemsBlock">
                    {db.items.sort(() => Math.random() - 0.5).slice(0, 5).map((item) => {
                        return <nav><NavLink to={`/items/${item.id}`} className="NavLink"> <ItemCard key={item.id} item={item} /></NavLink></nav>
                    })}
                </div>
            </div>
        </div>
    );
}

export default ItemsBlock;
import "../styles/ServicesCardMini.css"
import arrow from "../image/arrowRight.svg"

const ServicesCardMini = ({ card }) => {
    return (
        <div className="cardMini">
            <img className="imageCardMini" alt="services_image" src={`${card.image}`}></img>
            <div className="cardMiniBG" style={{ backgroundColor: card.color }}>
                <div className="cardMiniContent">
                    <span className="cardMiniText" style={{ color: card.textcolor }}>{card.text}</span>
                    <div className="huita">
                        <div className="cardMiniMore">
                            <span className="cardMiniMoreText">Подробнее</span>
                            <img src={arrow} alt="arrow" className="arrowMoreMini" />
                        </div>
                    </div>
                </div>
                <div className="cardMiniH1Box">
                    <div className="cardMiniTextAlign"><span className="cardMiniH1" style={{ color: card.textcolor }}>{card.name}</span></div>
                </div>
            </div>
        </div>
    );
}

export default ServicesCardMini;
import "../styles/Banner.css"
import imagebanner from "../image/banner.jpg"
import arrow from "../image/arrow.svg"
import { NavLink } from "react-router-dom";
import {Link} from "react-scroll"

const Banner = () => {
    return (
        <div className="banner">
            <div className="all-banner-align">
                <div className="slogan-align">
                    <h1 className="slogan">Соединяем ваши идеи с жизнью, мастерством сварки - МехЦех 2020</h1>
                </div>
                <div className="banner-align">
                    <div className="banner-content">
                        {/*<div className="image-banner"></div>*/}
                        <div className="image-banner"><img className="image-banner" src={imagebanner} alt="banner"></img></div>
                        <nav><Link Link to="itemblocklink" spy={true} smooth={true} offset={-150} duration={900} activeClass="active" className="link-block">
                            <div className="link-div"><h1 className="link-banner">Товары</h1></div>
                            <div className="arrow-block">
                                <span className="arrow-text">Подробнее</span>
                                <img src={arrow} alt="arrow" className="arrow" />
                            </div>
                        </Link>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
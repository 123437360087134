import { useParams } from "react-router-dom";
import db from "../db.json"
import { Navigate } from 'react-router-dom';
import "../styles/Item.css"
import arrow from "../image/arrowRight.svg"
import Gallery from "../components/Gallery";

const ItemPage = () => {
    const { id } = useParams();
    let item = db.items.find(el => el.id === id) ?? null;

    if (item === null) {
        return <Navigate to='/404' />;
    }

    function modal() {
        document.getElementById("modal").style.display = 'flex';
        document.body.style.overflow = 'hidden';
    }

    function CloseModal(){
        document.getElementById("modal").style.display = 'none';
        document.body.style.overflow = '';
    }

    return (
        <div className="currentItemAlign">
            <div className="bgDiv" id="modal" style={{display: "none"}}>
                <div className="bgImage"  onClick={() => CloseModal()}></div>
                <div className="bgText">
                <div className="telText"><span className="tel">Номер: +7(930) 988-91-98</span></div>
                </div>
            </div>

            <h1 className="currentItemName">{item.name.substring(0, item.name.indexOf(","))}</h1>
            <div className="itemDiv">
                <div className="itemImage" style={{ backgroundImage: `url(${item.image})` }}></div>
                <div className="currentContent">
                    <div className="parametersDiv">
                        <div className="materialsParameter">
                            <span className="parameterText">Материалы:</span>
                            <span className="parameterValue">{item.materials}</span>
                        </div>
                        <div className="sizeParameter">
                            <span className="parameterText">Размер:</span>
                            <span className="parameterValue">{item.size}</span>
                        </div>
                        <div className="priceParameter">
                            <span className="parameterText">Цена:</span>
                            <span className="parameterValue">{item.price} Руб</span>
                        </div>
                        <div className="orderBlock" onClick={() => modal()}>
                            <span className="moreText">Заказать</span>
                            <img src={arrow} alt="arrow" className="arrowMore" />
                        </div>
                    </div>
                </div>
                <span className="description">{item.description}</span>
            </div>
            <Gallery data={db} />
        </div>
    );
}

export default ItemPage;
import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/Header';
import HomePage from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ItemsPage from './pages/Items';
import "./styles/SrbijaSans.css"
import ItemPage from './pages/Item';
import Page404 from './pages/404';


const App = () => {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path='/items' element={<ItemsPage />} />
          <Route path='/items/:id' element={<ItemPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));



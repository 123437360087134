import "../styles/Header.css"
import logo from "../image/logo.svg"
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll"
import { useParams, useLocation } from "react-router-dom";

const Header = () => {

    let location = useLocation();

    return (
        <div className="header">
            <div className="header-align">
                <img className="image-logo" src={logo} alt="logo"></img>
                <div className="links">
                    <nav><NavLink to="" className="link">Главная</NavLink></nav>
                    <nav><NavLink to="/items" className="link">Товары</NavLink></nav>
                    {location.pathname !== '/' ? (<nav><NavLink to="" onClick={() => {window.scrollTo(0, 3500);}} className="link">Услуги</NavLink></nav>) : (<nav><Link to="services" spy={true} smooth={true} offset={-20} duration={900} activeClass="active" className="link">Услуги</Link></nav>)}
                    <nav><NavLink to="/about" className="link">О нас</NavLink></nav>
                </div>
            </div>
        </div>
    );


}

export default Header;
import "../styles/ItemCard.css"
import arrow from "../image/arrowRight.svg"

const ItemCard = ({ item }) => {
    return (
        <div className="item">
            <div className="cardImage" style={{ backgroundImage: `url(${item.image})` }}></div>
            <span className="itemName">{item.name}</span>
            <span className="price">{item.price} Руб</span>
            <div className="moreBlockAlign">
                <div className="moreBlock">
                    <span className="moreText">Подробнее</span>
                    <img src={arrow} alt="arrow" className="arrowMore" />
                </div>
            </div>
        </div>
    );
}

export default ItemCard;